import * as baseApi from "./baseApi";

export const addUserToGroup = async ({ groupName, email, sub }) => {
  const params = {
    groupname: groupName,
    username: sub,
    email: email,
  };

  return await baseApi.post({
    url: "cognito/users/addtogroup",
    payload: params,
  });
};

export const addOrgUserType = async (values) => {
  return await baseApi.post({
    url: "my-company/addOrgUserType",
    payload: values,
  });
};
