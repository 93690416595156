// Package Imports
import React, { useState } from "react";
import { message, Input } from "antd";
import { useMutation } from "@apollo/client";
import { Link } from "@reach/router";

// Internal Imports
import { HeadingOne, BodyOne } from "../components/typography";
import BlueButton from "../components/buttons/BlueButton";
import AuthForm from "../components/forms/AuthForm";
import { Error } from "../components/feedback";
import { LOGIN } from "../graphql/Mutations";
import { base64Encoder, base64Decoder, allowedUserType, UserException } from "../utils/helpers.js";
import { cognitoDetails, get_all_user_data } from "../services/AuthActions";

const Login = (props) => {
    const { navigate } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [loginForm, setLoginForm] = useState({
        email: '',
        password: ''
    });

    const [requestJWT] = useMutation(LOGIN, {
        variables: {
            email: loginForm.email.toLowerCase(),
            password: loginForm.password,
            clientID: process.env.REACT_APP_CLIENT_ID
        },
        onCompleted: async (data) => {
            try {
                const userTypeCheck = await allowedUserType(data.login.accessToken.jwt);
                if (!userTypeCheck) { setIsLoading(false); return message.error('Your account type is currently not supported'); };

                const userDetails = {
                    email: loginForm.email.toLowerCase(),
                    password: base64Decoder(loginForm.password)
                }

                // Get Cognito Value - sub
                const cognitoDetailsObject = await cognitoDetails(userDetails)
                    .then(fulfilled => {
                        return fulfilled;
                    }, () => {
                        return null;
                    });

                if (cognitoDetailsObject === null) {
                    message.error('Could not retrieve user data');
                    throw new UserException("Failed to get Cognito Details");
                }

                // Get Organisation Value - org_id
                const organisationDetailsObject = await get_all_user_data(cognitoDetailsObject)
                    .then(fulfilled => {
                        return fulfilled[0];
                    }, () => {
                        return null;
                    });

                if (organisationDetailsObject === null) {
                    message.error('Could not retrieve organisation data');
                    throw new UserException("Failed to get Org-Data");
                }

                const userData = JSON.stringify({
                    token: data.login.accessToken.jwt,
                    refresh: data.login.refreshToken,
                    sub: cognitoDetailsObject.sub,
                    org_id: organisationDetailsObject.org_id
                });

                setIsLoading(false);
                message.success('Login Successful!');
                navigate(`/login-redirect/tripplo-mobile-auth/${userData}`);
            } catch(e) {
                setIsLoading(false);
                Error({
                    message: "Something went wrong...",
                    description: "Please contact our support services"
                });
                throw new UserException("Login Process Failed");
            }
        }
    });

    const handleChange = (e, field) => {
        const formState = { ...loginForm };

        formState[field] = 
            field === 'password'
                ? base64Encoder(e.currentTarget.value)
                : e.currentTarget.value;
        
        setLoginForm(formState);
    }

    const handlerLogin = async () => {
        if (loginForm.email.length <= 8 && loginForm.password.length === 0) {
            message.error('Please enter a valid email address or password.')
            return
        }
        setIsLoading(true);
        await requestJWT()
            .catch((res) => {
                setIsLoading(false);
                res.graphQLErrors.forEach((err) => {
                    message.error(err.message);
                });
            });
        
    }

    return (
        <div className="flex justify-center mt-12">
            <div className="grid grid-rows-2 place-content-between gap-20">
                <div>
                    <HeadingOne className="text-tripplo-200 text-center mt-4 mb-4">
                        Login
                    </HeadingOne>

                    <AuthForm height="235px" active={true}>
                        <div className="flex flex-col p-2 mb-3">
                            <div className="flex flex-col">
                                <BodyOne className="mb-2">Email Address</BodyOne>
                                <Input size="large" placeholder="Enter your email address here" onChange={(e) => handleChange(e, "email")} />
                            </div>
                            <div className="flex flex-col mt-3">
                                <BodyOne className="mb-2">Password</BodyOne>
                                <Input.Password size="large" placeholder="Enter your password here" onChange={(e) => handleChange(e, "password")} />
                            </div>
                        </div>
                    </AuthForm>
                </div>

                <div className="flex flex-col">
					<BodyOne className="text-center">
						By continuing you agree <br /> to our <Link className="text-tripplo-200 hover:text-tripplo-200" to="/legal/terms-of-use">Terms of Service</Link> and <Link className="text-tripplo-200 hover:text-tripplo-200" to="/legal/privacy-policy">Privacy Policy</Link>
					</BodyOne>

                    <BlueButton
                        className="w-full mt-4"
                        onClick={() => handlerLogin()}
                        isLoading={isLoading}
                    >
                        Login
                    </BlueButton>
                    <BodyOne className="text-center mt-2">
                        Don't have an account ?{" "}
                        <span
                            className="text-tripplo-200 font-medium"
                            onClick={() => navigate('/signup/mobile-app')}
                        >
                            Create account
                        </span>
                    </BodyOne>
                    <BodyOne className="text-center">
                        Would you like to remove your account ?<br></br>
                        <a
                            className="text-tripplo-200 font-medium"
                            href="mailto:support@tripplo.co?subject=Tripplo - Account Removal Request&body=Please supply your email address below. Account deletion requests can take up to 72Hrs to be processed. You will be contacted by a customer service agent to complete the deletion process.%0A%0A"
                        >
                            Request account removal
                        </a>
                    </BodyOne>
                </div>
            </div>
        </div>
    )
}

export default Login
