import gql from "graphql-tag";

export const HELLO_QUERY = gql`
  query HELLO_QUERY {
    hello
  }
`;

export const CHECK_IF_COMPANY_EXISTS = gql`
  query CHECK_IF_COMPANY_EXISTS($companyName: String!, $checkType: String!) {
    checkIfCompanyExists(companyName: $companyName, checkType: $checkType) {
      status
      description
    }
  }
`;
