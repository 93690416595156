import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import AuthForm from "../../components/forms/AuthForm";
import { useSetRecoilState } from "recoil";
import { onboardingState } from "../../atoms";
import { BodyOne, HeadingOne } from "../../components/typography";
import { Input, Autocomplete } from "../../components/inputs";
import BlueButton from "../../components/buttons/BlueButton";
import { RemoveIcon } from "../../assets/icons";
import { make } from "../../constants";
const TrucksForm = (props) => {
  const setOnboardingState = useSetRecoilState(onboardingState);
  const { handleSubmit, errors, control } = useForm();

  const [formTrucks, setFormTrucks] = useState([]);
  // console.log("onboardingData", onboardingData);
  const removeTruck = (index) => {
    const t = [...formTrucks];
    t.splice(index, 1);
    setFormTrucks(t);
  };

  const updateTruck = ({ index, field, value }) => {
    const t = [...formTrucks];
    t[index][field] = value;
    setFormTrucks(t);
  };
  const addTruck = () => {
    const t = [...formTrucks];
    t.push({
      licence_no: null,
      make: null,
      series: null,
    });
    setFormTrucks(t);
  };

  const onSubmit = (data) => {
    console.log("data", data, formTrucks);
    setOnboardingState((onboardingState) => ({
      ...onboardingState,
      fleet: {
        trucks: formTrucks,
      },
    }));
    props.setActiveKey("DriversForm");
  };
  const inputs = formTrucks.map((t, index) => {
    const count = index + 1;
    return (
      <AuthForm height="350px" active="Signup" key={index}>
        <div className="flex justify-between p-4">
          <p className="font-poppins font-semibold text-blue-500 ">
            Truck {count}
          </p>
          <span onClick={() => removeTruck(index)} className="flex flex-row">
            <RemoveIcon /> Remove{" "}
          </span>
        </div>

        <div className="grid grid-cols-2">
          <div className="flex flex-col p-2 mb-3">
            <BodyOne className="mb-2 ">Registration number</BodyOne>
            <Controller
              name={`regNumber.${index}`}
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={(e) =>
                    updateTruck({
                      index,
                      field: "licence_no",
                      value: e.currentTarget.value,
                    })
                  }
                  value={value}
                />
              )}
            />
            {errors.regNumber && (
              <BodyOne className="text-red-600">
                Please enter your Registration number
              </BodyOne>
            )}{" "}
          </div>
          <div className="flex flex-col p-2 mb-3">
            <BodyOne className="mb-2 ">Make </BodyOne>
            <Controller
              name={`make.${index}`}
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <Autocomplete
                  options={make}
                  onChange={onChange}
                  onSelect={(e) =>
                    updateTruck({
                      index,
                      field: "make",
                      value: e,
                    })
                  }
                />
              )}
            />
            {errors.make && (
              <BodyOne className="text-red-600">Please enter your make</BodyOne>
            )}{" "}
          </div>
          <div className="flex flex-col p-2 mb-3">
            <BodyOne className="mb-2 ">Model</BodyOne>
            <Controller
              name={`model.${index}`}
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <Input
                  onBlur={(e) =>
                    updateTruck({
                      index,
                      field: "series",
                      value: e.currentTarget.value,
                    })
                  }
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {errors.model && (
              <BodyOne className="text-red-600">
                Please enter your model
              </BodyOne>
            )}{" "}
          </div>
        </div>
      </AuthForm>
    );
  });
  return (
    <div className=" flex justify-center ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid justify-items-center">
          <div>
            <HeadingOne className="text-tripplo-200 font-semibold text-center mt-4 mb-4">
              My trucks
            </HeadingOne>
            {inputs}
            <BlueButton onClick={() => addTruck()}>+</BlueButton>
          </div>
          <div className="flex justify-between  mt-10">
            <BlueButton
              className=""
              onClick={() => props.setActiveKey("FleetForm")}
              // isLoading={loading}
            >
              Previous
            </BlueButton>

            <BlueButton
              className=""
              type="submit"
              // isLoading={loading}
            >
              Next
            </BlueButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TrucksForm;
