import React, { useState, useEffect } from "react";
import { Alert } from "antd";
import { HeadingOne } from "../../components/typography";
import BlueButton from "../../components/buttons/BlueButton";
import AuthForm from "../../components/forms/AuthForm";
import CognitoButton from "../../components/buttons/CognitoButton";
import { Success, Error } from "../../components/feedback";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "../../atoms";
import {
  cargo_broker_icon,
  transporter_icon,
  cargo_owner_icon,
} from "../../assets/icons";
import { addUserToGroup, addOrgUserType } from "../../services/organisations";
const OrganistationForm = (props) => {
  const [selectedBroker, setSelectedBroker] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(false);
  const [group, setGroup] = useState([]);
  const [cognitoGroup, setCognitoGroup] = useState("444");
  const [selectedTransporter, setSelectedTransporter] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userData = useRecoilValue(userState);
  const setUserState = useSetRecoilState(userState);

  console.log("cognitoGroup", cognitoGroup, group);
  //ONCE the user has selected their user type(s)
  //we need to decoded it to match
  //what is on AWS Cognito to add them
  //into a group.
  useEffect(() => {
    if (group.includes("CargoBroker") && group.includes("Transporter")) {
      setCognitoGroup("TransBroker");
    } else if (group.includes("CargoOwner") && group.includes("Transporter")) {
      setCognitoGroup("Admin");
    } else if (group.length === 0) {
      setCognitoGroup("");
    } else {
      setCognitoGroup(group[0]);
    }
  }, [group]);

  //this function adds the user to Cognito
  //via our own auth service
  //tracks the user via mixpanel

  //allow the user to select multiple user
  //types
  const selectGroup = (groupString) => {
    //make a copy of the current
    //state
    const copy = [...group];

    //if there are two types in the array do not do anything
    if (copy.length === 2) {
      return null;
      // check if the array has one of the cargo options
      //make sure you dont add both
    } else if (copy.includes("CargoBroker") && groupString === "CargoOwner") {
      return null;
    } else if (copy.includes("CargoOwner") && groupString === "CargoBroker") {
      return null;
    } else {
      //if the array does not have the user type and the array is not eqyaul to 2
      if (!copy.includes(groupString) && copy.length !== 2) {
        copy.push(groupString);
        setGroup(copy);
      }
      //based on the user type pass down state
      //to the relevant component.
      if (groupString === "CargoBroker") {
        setSelectedBroker(true);
      } else if (groupString === "CargoOwner") {
        setSelectedOwner(true);
      } else {
        setSelectedTransporter(true);
      }
    }
  };

  //a function to deselct the user types
  //add unhighlight the relevant button.
  const onClose = () => {
    setShowAlert(false);
  };
  const deselectGroup = (groupString) => {
    const copy = [...group];

    if (groupString === "CargoBroker") {
      setSelectedBroker(false);
      const newCopy = copy.filter((i) => i !== "CargoBroker");
      setGroup(newCopy);
    } else if (groupString === "CargoOwner") {
      setSelectedOwner(false);
      const newCopy = copy.filter((i) => i !== "CargoOwner");
      setGroup(newCopy);
    } else {
      setSelectedTransporter(false);
      const newCopy = copy.filter((i) => i !== "Transporter");
      setGroup(newCopy);
    }
  };

  //function that sends data to api

  const addUserToCognitoGroup = async () => {
    try {
      setIsLoading(true);
      await addUserToGroup({
        groupName: cognitoGroup,
        sub: userData.sub,
        email: userData.email,
      });

      let sessionData = { ...userData };
      delete sessionData.password;
      sessionData.user_type = cognitoGroup;

      setUserState(() => ({
        ...sessionData,
      }));
      //for pure co/cbs to get their orgs registered
      if (cognitoGroup === "CargoBroker" || cognitoGroup === "CargoOwner") {
        await addOrgUserType(sessionData);
      }
      Success({
        message: "Success",
        description: "You have succesfully selected an organisation type.",
      });
      setIsLoading(false);

      props.setActiveKey("FleetForm");
    } catch (e) {
      setIsLoading(false);

      Error({
        message: "Sorry, something came up",
        description: e.message,
      });
    }
  };
  return (
    <>
      {showAlert && (
        <Alert
          message={`You have chosen the following type of organisation: ${
            group === "CargoBroker"
              ? "Cargo Broker"
              : group === "CargoOwner"
              ? "Cargo Owner"
              : group
          }`}
          type="info"
          closable
          onClose={onClose}
          style={{ marginBottom: "2rem" }}
        />
      )}
      <div className=" flex justify-center ">
        <div className="grid justify-items-center">
          <div>
            <HeadingOne className="text-tripplo-200 font-semibold text-center mt-4 mb-4">
              Type of organistation
            </HeadingOne>
            <AuthForm height="350px" active="Signup">
              <div className="grid grid-rows-3 gap-2">
                <CognitoButton
                  selected={selectedBroker}
                  onClick={() =>
                    selectedBroker
                      ? deselectGroup("CargoBroker")
                      : selectGroup("CargoBroker")
                  }
                  buttonTitle="Cargo broker"
                  Icon={cargo_broker_icon}
                />
                <CognitoButton
                  selected={selectedTransporter}
                  onClick={() =>
                    selectedTransporter
                      ? deselectGroup("Transporter")
                      : selectGroup("Transporter")
                  }
                  buttonTitle="Transporter"
                  Icon={transporter_icon}
                />
                <CognitoButton
                  onClick={() =>
                    selectedOwner
                      ? deselectGroup("CargoOwner")
                      : selectGroup("CargoOwner")
                  }
                  selected={selectedOwner}
                  buttonTitle="Cargo owner"
                  Icon={cargo_owner_icon}
                />
              </div>
            </AuthForm>
          </div>
          <div className="flex flex-col w-full mt-10">
            <BlueButton
              className="w-full "
              disabled={cognitoGroup.length > 0 ? false : true}
              onClick={() => addUserToCognitoGroup()}
              isLoading={isLoading}
            >
              Next
            </BlueButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganistationForm;
