// Package Imports
import React, { useState } from "react";

// Components
import { user_types } from "../../constants";

const AccountTypeItem = ({
  AccountIcon,
  accountType,
  accountValue,
  onChange,
  selected,
  setSelected,
}) => {
  return (
    <button
      value={accountValue}
      type="button"
      className={
        selected
          ? "p-4 flex justify-center bg-tripplo-200 w-full rounded-lg  border border-tripplo-300"
          : "p-4 flex justify-center bg-tripplo-600 w-full rounded-lg  border border-tripplo-300"
      }
      onClick={(e) => {
        setSelected(!selected);
        onChange(e.currentTarget.value);
      }}
    >
      <div
        className="flex flex-row flex-nowrap w-full justify-center self-center py-4"
        value={accountValue}
      >
        <div className="pr-2">
          <AccountIcon />
        </div>
        <span className="flex self-center font-bold text-lg pl-2">
          {accountType}
        </span>
      </div>
    </button>
  );
};

const AccountTypeBlock = ({ onChange }) => {
  const [selected, setSelected] = useState(false);

  return (
    <div className="flex flex-col flex-wrap gap-y-4 relative">
      {user_types.map((type) => {
        return (
          <AccountTypeItem
            key={type.id}
            AccountIcon={type.icon}
            accountType={type.title}
            accountValue={type.value}
            onChange={onChange}
            setSelected={setSelected}
            selected={selected}
          />
        );
      })}
    </div>
  );
};

export default AccountTypeBlock;
