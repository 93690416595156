import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import _ from "lodash";
import AuthForm from "../../components/forms/AuthForm";
import { BodyOne, HeadingOne } from "../../components/typography";
import { Dropdown } from "../../components/inputs";
import { onboardingState } from "../../atoms";
import BlueButton from "../../components/buttons/BlueButton";
const FleetForm = (props) => {
  const setOnboardingState = useSetRecoilState(onboardingState);
  const { handleSubmit, errors, control } = useForm();
  const onSubmit = async (data) => {
    console.log("data", data);

    setOnboardingState((onboardingState) => ({
      surveyData: {
        fleet: {
          trucks: data.trucks,
          trailers: data.trailers,
          drivers: data.drivers,
        },
      },
    }));
    props.setActiveKey("RoutesForm");
  };
  return (
    <div className=" flex justify-center ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid justify-items-center">
          <div>
            <HeadingOne className="text-tripplo-200 font-semibold text-center mt-4 mb-4">
              My fleet size{" "}
            </HeadingOne>
            <AuthForm height="350px" active="Signup">
              <div className="flex flex-col p-2 mb-3">
                <BodyOne className="mb-2 ">Number of trucks</BodyOne>
                <Controller
                  name="trucks"
                  control={control}
                  defaultValue={0}
                  rules={{ required: true }}
                  render={({ onChange, value }) => (
                    <Dropdown onChange={onChange} value={value}>
                      {_.range(0, 200).map((number, index) => (
                        <option
                          key={index}
                          value={number.toString()}
                          defaultValue={number.toString()}
                        >
                          {number.toString()}
                        </option>
                      ))}
                    </Dropdown>
                  )}
                />
                {errors.trucks && (
                  <BodyOne className="text-red-600">
                    Please specify how many trucks you have
                  </BodyOne>
                )}{" "}
              </div>
              <div className="flex flex-col p-2 mb-3">
                <BodyOne className="mb-2 ">Number of trailers</BodyOne>
                <Controller
                  name="trailers"
                  control={control}
                  defaultValue={0}
                  rules={{ required: true }}
                  render={({ onChange, value }) => (
                    <Dropdown onChange={onChange} value={value}>
                      {_.range(0, 200).map((number, index) => (
                        <option
                          key={index}
                          value={number.toString()}
                          defaultValue={number.toString()}
                        >
                          {number.toString()}
                        </option>
                      ))}
                    </Dropdown>
                  )}
                />
                {errors.trailers && (
                  <BodyOne className="text-red-600">
                    Please specify how many trailers you have
                  </BodyOne>
                )}{" "}
              </div>
              <div className="flex flex-col p-2 mb-3">
                <BodyOne className="mb-2 ">Number of drivers</BodyOne>
                <Controller
                  name="drivers"
                  control={control}
                  defaultValue={0}
                  rules={{ required: true }}
                  render={({ onChange, value }) => (
                    <Dropdown onChange={onChange} value={value}>
                      {_.range(0, 200).map((number, index) => (
                        <option
                          key={index}
                          value={number.toString()}
                          defaultValue={number.toString()}
                        >
                          {number.toString()}
                        </option>
                      ))}
                    </Dropdown>
                  )}
                />
                {errors.drivers && (
                  <BodyOne className="text-red-600">
                    Please specify how many drivers you have
                  </BodyOne>
                )}{" "}
              </div>
            </AuthForm>
          </div>
          <div className="flex flex-col w-full mt-10">
            <BlueButton
              className="w-full "
              type="submit"
              // isLoading={loading}
            >
              Submit
            </BlueButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FleetForm;
