import { transporter_icon } from "../assets/icons";
export const countries = [
  { id: 1, country: "Angola", flag: "🇦🇴" },
  { id: 2, country: "Botswana", flag: "🇧🇼" },
  { id: 3, country: "Comoros", flag: "🇰🇲" },
  { id: 4, country: "Democratic Republic of Congo", flag: "🇨🇩" },
  { id: 5, country: "Lesotho", flag: "🇱🇸" },
  { id: 6, country: "Madagascar", flag: "🇲🇬" },
  { id: 7, country: "Malawi", flag: "🇲🇼" },
  { id: 8, country: "Mauritius", flag: "🇲🇺" },
  { id: 9, country: "Mozambique", flag: "🇲🇿" },
  { id: 10, country: "Namibia", flag: "🇳🇦" },
  { id: 11, country: "Seychelles", flag: "🇸🇨" },
  { id: 12, country: "South Africa", flag: "🇿🇦" },
  { id: 13, country: "Swaziland", flag: "🇸🇿" },
  { id: 14, country: "Tanzania", flag: "🇹🇿" },
  { id: 15, country: "Zambia", flag: "🇿🇲" },
  { id: 16, country: "Zimbabwe", flag: "🇿🇼" },
];

export const trailer_makes = [
  "Afrit",
  "Anchor Bodies",
  "Bahrain",
  "Barkit",
  "Bitueman",
  "Burg Trailer",
  "Busaf",
  "Bussing",
  "Changing Tides",
  "CI",
  "Clark",
  "Cobalt",
  "Drawabar",
  "Duncan Andrew",
  "Fridge",
  "Global",
  "Harland",
  "Hartland",
  "Henred Fruehauf",
  "Home Built",
  "Homez",
  "Ice Cold Bodies",
  "Inline",
  "JB Brockhouse",
  "JDC",
  "Kearney's",
  "M&P Bodies",
  "Master",
  "Motor Trail",
  "Multi-transquip",
  "Palleter",
  "Paramount",
  "Poole",
  "Rapid Truck Bodies",
  "Road Dog",
  "RSA",
  "SA Truck Bodies",
  "Samtor",
  "SATRA",
  "Serco",
  "Southern Star",
  "Sportrailer",
  "Swift",
  "Tank Clinic",
  "Tee",
  "Tee/Ete",
  "Top Trailer",
  "Trailer Enterprise",
  "Trailstar",
  "UBT",
  "Venter",
  "Zelna",
  "Other",
  "Trailord",
];

export const trailer_types = [
  "Superlink Cable Carrier",
  "Superlink Car Carrier",
  "Superlink Cement Bulker",
  "Superlink Closed Body",
  "Superlink CurtainSide",
  "Superlink Drawbar",
  "Superlink DropSide",
  "Superlink EndTipper",
  "Superlink FlatDeck",
  "Superlink Fridge",
  "Superlink HighSided",
  "Superlink Pantechnicons",
  "Superlink Personal Carriers",
  "Superlink SideTipper",
  "Superlink Skeletal",
  "Superlink Tanker",
  "Superlink Tautliner",
  "Superlink Bottom Dumper",
  "Superlink Dolly",
  "Interlink Cable Carrier",
  "Interlink Car Carrier",
  "Interlink Cement Bulker",
  "Interlink Closed Body",
  "Interlink CurtainSide",
  "Interlink Drawbar",
  "Interlink DropSide",
  "Interlink EndTipper",
  "Interlink FlatDeck",
  "Interlink Fridge",
  "Interlink HighSided",
  "Interlink Pantechnicons",
  "Interlink Personal Carriers",
  "Interlink SideTipper",
  "Interlink Skeletal",
  "Interlink Tanker",
  "Interlink Tautliner",
  "Interlink Bottom Dumper",
  "Interlink Dolly",
  "Single Axle Cable Carrier",
  "Single Axle Car Carrier",
  "Single Axle Cement Bulker",
  "Single Axle Closed Body",
  "Single Axle CurtainSide",
  "Single Axle Drawbar",
  "Single Axle DropSide",
  "Single Axle EndTipper",
  "Single Axle FlatDeck",
  "Single Axle Fridge",
  "Single Axle HighSided",
  "Single Axle Pantechnicons",
  "Single Axle Personal Carriers",
  "Single Axle SideTipper",
  "Single Axle Skeletal",
  "Single Axle Tanker",
  "Single Axle Tautliner",
  "Single Axle Bottom Dumper",
  "Single Axle Dolly",
  "Double Axle Cable Carrier",
  "Double Axle Car Carrier",
  "Double Axle Cement Bulker",
  "Double Axle Closed Body",
  "Double Axle CurtainSide",
  "Double Axle Drawbar",
  "Double Axle DropSide",
  "Double Axle EndTipper",
  "Double Axle FlatDeck",
  "Double Axle Fridge",
  "Double Axle HighSided",
  "Double Axle Pantechnicons",
  "Double Axle Personal Carriers",
  "Double Axle SideTipper",
  "Double Axle Skeletal",
  "Double Axle Tanker",
  "Double Axle Tautliner",
  "Double Axle Bottom Dumper",
  "Double Axle Dolly",
  "Tri Axle Cable Carrier",
  "Tri Axle Car Carrier",
  "Tri Axle Cement Bulker",
  "Tri Axle Closed Body",
  "Tri Axle CurtainSide",
  "Tri Axle Drawbar",
  "Tri Axle DropSide",
  "Tri Axle EndTipper",
  "Tri Axle FlatDeck",
  "Tri Axle Fridge",
  "Tri Axle HighSided",
  "Tri Axle Pantechnicons",
  "Tri Axle Personal Carriers",
  "Tri Axle SideTipper",
  "Tri Axle Skeletal",
  "Tri Axle Tanker",
  "Tri Axle Tautliner",
  "Tri Axle Bottom Dumper",
  "Tri Axle Dolly",
  "Tandem Axle Cable Carrier",
  "Tandem Axle Car Carrier",
  "Tandem Axle Cement Bulker",
  "Tandem Axle Closed Body",
  "Tandem Axle CurtainSide",
  "Tandem Axle Drawbar",
  "Tandem Axle DropSide",
  "Tandem Axle EndTipper",
  "Tandem Axle FlatDeck",
  "Tandem Axle Fridge",
  "Tandem Axle HighSided",
  "Tandem Axle Pantechnicons",
  "Tandem Axle Personal Carriers",
  "Tandem Axle SideTipper",
  "Tandem Axle Skeletal",
  "Tandem Axle Tanker",
  "Tandem Axle Tautliner",
  "Tandem Axle Bottom Dumper",
  "Tandem Axle Dolly",
  "Double Axle DoubleDeck Car Carrier",
  "Single Deck Cable Carrier",
  "Single Deck Car Carrier",
  "Single Deck Cement Bulker",
  "Single Deck Closed Body",
  "Single Deck CurtainSide",
  "Single Deck Drawbar",
  "Single Deck DropSide",
  "Single Deck EndTipper",
  "Single Deck FlatDeck",
  "Single Deck Fridge",
  "Single Deck HighSided",
  "Single Deck Pantechnicons",
  "Single Deck Personal Carriers",
  "Single Deck SideTipper",
  "Single Deck Skeletal",
  "Single Deck Tanker",
  "Single Deck Tautliner",
  "Single Deck Bottom Dumper",
  "Single Deck Dolly",
  "DoubleDeck Car Carrier",
  "Other",
];

export const validators = {
  passwordValidations: [
		{ pat: /(?=.*[A-Z])/, msg: 'uppercase' },
		{ pat: /(?=.*[0-9])/, msg: 'number' },
	],
  isPasswordValid: (value) => {
    let error;
    validators.passwordValidations.forEach(({ pat, msg }) => {
			if (!pat.test(value)) {
				error= `Password must contain at least one ${msg}.`
        return;
			}
		})
    if(!error){
      if (!/.{8,}/.test(value)) {
        return 'Password must be at least 8 characters.';
      }
      return true;
    }
    return error;
  },

  getPasswordValidatorStates: (value) => {
    return [{pat: /.{8,}/}, ...validators.passwordValidations].map(({pat}) => pat.test(value));
  },

  isEmailValid: (value) => {
    return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
  }
}
export const make = [
  { label: "Daewoo", value: "Daewoo" },
  { label: "DAF", value: "DAF" },
  { label: "Dongfeng", value: "Dongfeng" },
  { label: "ERF", value: "ERF" },
  { label: "FAW", value: "FAW" },
  { label: "Freightliner", value: "Freightliner" },
  { label: "Fuso", value: "Fuso" },
  { label: "Hino", value: "Hino" },
  { label: "International", value: "International" },
  { label: "Isuzu", value: "Isuzu" },
  { label: "Iveco", value: "Iveco" },
  { label: "MAN", value: "MAN" },
  { label: "Mercedes Benz", value: "Mercedes Benz" },
  { label: "Mitsubishi", value: "Mitsubishi" },
  { label: "Powerland", value: "Powerland" },
  { label: "Powerstar", value: "Powerstar" },
  { label: "Renault", value: "Renault" },
  { label: "Scania", value: "Scania" },
  { label: "Tata", value: "Tata" },
  { label: "UD", value: "UD" },
  { label: "Volkswagen", value: "Volkswagen" },
  { label: "Volvo", value: "Volvo" },
  { label: "Other", value: "Other" },
];

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0;
    let v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const user_types = [
  {
    id: 1,
    title: "Transporter",
    value: "Transporter",
    icon: transporter_icon,
  },
];
