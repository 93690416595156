/*

    Amplify-Actions
        -   The below authentication helper functions abstract dealing with auth actions directly within components
        -   Registration and Login have their own specific handlers, they are not interchangeable
        -   Registration requires a more modular approach to gathering data
        -   Login only requires a large blob of user data

*/

// Package Imports
import { Auth } from "aws-amplify";
import { message } from "antd";
import { requestHandler } from "./baseApi";
import axios from "axios";
import { base64Encoder, UserException } from "../utils/helpers";

// FUNC - Create a confirmed user within Cognito User Pool
export const userSignUp = async (mutator, formObject) => {
    // The catch statement on the mutator is used because the try/catch block
    // does not catch the Apollo graphQLErrors when thrown.
    await mutator({
        variables: {
            SignUpInput: {
                email: formObject.email,
                phoneNumber: `+${formObject.phone_number}`,
                gender: formObject.gender,
                firstName: formObject.name,
                lastName: formObject.family_name,
                password: formObject.password
            }
        }
    }).catch((res) => {
        res.graphQLErrors.forEach((err) => {
            message.error(err.message);
        });
    });
}

// FUNC - Login and return the JWT Token
export const userLogin = async (formObject) => {
    const query = ` mutation LOGIN($email: String!, $password: String!, $clientID: String!) {
                        login(email: $email, password: $password, clientID: $clientID) {
                        accessToken {
                            jwt
                            expiry
                        }
                        refreshToken
                        }
                    }
                  `
    const { data } = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/auth`,
        method: "POST",
        data: JSON.stringify({
            query,
            variables: {
                email: formObject.email,
                password: base64Encoder(formObject.password),
                clientID: process.env.REACT_APP_CLIENT_ID
            }
        }),
        headers: {
            Accept: "*/*"
        }
    }).catch(() => {
        message.error("Login Failed");
        throw new UserException("Login Failed");
    });

    return data.data;
}

// FUNC - Authenticate and return Cognito Username & Sub
export const cognitoDetails = async (formObject) => {
    const details = await Auth.signIn(formObject.email, formObject.password)
        .catch(() => {
            message.error("Could not retrieve user-details");
            throw new UserException("Failed to fecth Cognito Details");
        });

    const userDetails = {
        username: details.username,
        sub: details.attributes.sub
    }

    message.success("Sign-In Successful");
    await Auth.signOut();
    return userDetails;
}

// FUNC - Assign user to Cognito Group
export const assign_user_to_usertype_group = async (formObject) => {
    const requestParams = {
        username: formObject.username,
        email: formObject.email,
        groupname: formObject.user_type
    }

    const res = await requestHandler({
        method: "POST",
        token: formObject.jwt,
        url_extension: "cognito/users/addtogroup",
        params: requestParams
    }).catch(() => {
        message.error("Could not be added to group");
        throw new UserException("Assign to Group Failed");
    });

    return res.message;
}

// FUNC - Check if organization exists
export const check_existing_organization = async (formObject) => {
    const query = ` query CHECK_IF_COMPANY_EXISTS($companyName: String!, $checkType: String!) {
                        checkIfCompanyExists(companyName: $companyName, checkType: $checkType) {
                            status
                            description
                        }
                    }
                  `
    const { data } = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/my-company`,
        method: "POST",
        data: JSON.stringify({
            query,
            variables: {
                companyName: formObject.org_trading_name,
                checkType: "check"
            }
        }),
        headers: {
            Accept: "*/*"
        }
    }).catch(() => {
        message.error("Could not check existing organisation");
        throw new UserException("Failed to check existing org");
    });

    return data.data.checkIfCompanyExists;
}

// FUNC - Create New Organization
export const create_new_user_organization = async (formObject) => {
    const requestParams = {
        org_name: formObject.org_name,
        org_trading_name: formObject.org_trading_name,
        registration_country: formObject.registration_country
    }

    const res = await requestHandler({
        method: "POST",
        token: formObject.jwt,
        url_extension: "organisations",
        params: requestParams
    }).catch(() => {
        message.error("Could not create organisation");
        throw new UserException("Could not add new organisation");
    });

    return res;
}

// FUNC - Get User Details
export const get_all_user_data = async (formObject) => {
    const res = await requestHandler({
        org_check: true,
        method: "GET",
        url_extension: formObject.sub
    }).catch(() => {
        message.error("Could not retrieve user-data");
        throw new UserException("Failed to get all user data");
    });

    return res;
}