import { atom } from "recoil";

const userState = atom({
  key: "userState",
  default: {},
});

const onboardingState = atom({
  key: "onboardingState",
  default: {
    surveyData: {
      fleet: null,
      routes: [],
    },
    fleet: {},
  },
});

export { userState, onboardingState };

/*


const userState = atom({
  key: "userState",
  default: {
    email: "amomoloko+23@gmail.com",
    firstname: "Sir Frank",
    gender: "Female",
    lastname: "Will",
    password: "123456",
    cellphone: "+273453454643",
    sub: "a8359a25-413f-4107-9925-c00e74231bfa",
    org_name: "Def Jam",
    org_trading_name: "Def Jam",
    registration_country: "Malawi",
    org_id: "b6c26188-5ce0-4182-829b-63a1a71b6efc",
    userType: "Transporter",
  },
});

const onboardingState = atom({
  key: "onboardingState",
  default: {
    surveyData: {
      fleet: null,
      routes: [],
    },
    fleet: {
      trucks: [{ licence_no: "VBX 099", make: "Volvlo", series: "X-fightert" }],
      drivers: [{ first_name: "Hugh", last_name: "Masikela" }],
      trailers: [
        { licence_no: "VBX 099", make: "flatbed", series: "X-fightert" },
      ],
    },
  },
});
*/
