import React from "react";
import HeadingOne from "../components/typography/HeadingOne";
const Index = () => {
  console.log("process.env.REACT_APP_REGION_", process.env.REACT_APP_REGION_);
  return (
    <>
      <HeadingOne className="text-center text-blue-500">
        Tripplo Auth{" "}
      </HeadingOne>
    </>
  );
};

export default Index;
