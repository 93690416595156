import React from "react";

const CognitoButton = ({ Icon, onClick, selected, buttonTitle }) => {
  const styles = {
    button: {
      regular:
        "p-4 flex justify-center bg-tripplo-600 w-full rounded-lg  border border-tripplo-300",
      selectedd:
        "p-4 flex justify-center bg-tripplo-200 w-full rounded-lg  border border-tripplo-300",
    },
    text: {
      regular: "ml-5 mt-2 text-tripplo-200 font-poppins text-xl",
      selectedd: "ml-5 mt-2 text-white font-poppins text-xl",
    },
  };

  return (
    <button
      onClick={onClick}
      className={selected ? styles.button.selectedd : styles.button.regular}
    >
      <Icon />{" "}
      <p className={selected ? styles.text.selectedd : styles.text.regular}>
        {buttonTitle}
      </p>
    </button>
  );
};

export default CognitoButton;
