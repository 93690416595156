import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({ className, value, onChange, name, ...props }) => {
  return (
    <input
      className={`${className}
      bg-tripplo-600
      rounded-lg
      py-3
      px-16
      border
      text-black
      border-tripplo-300
      focus:outline-none
      focus:bg-white"`}
      value={value}
      onChange={onChange}
      {...props}
      name={name}
      type="checkbox"
    />
  );
};

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
};
Checkbox.propTypes = propTypes;
export default Checkbox;
