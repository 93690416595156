// Package Imports
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Link } from "@reach/router";
import { Input } from "antd";

// Components
import AuthForm from "../../../components/forms/AuthForm";
import { BodyOne, HeadingOne } from "../../../components/typography";
import { CelluarInput, CheckBox } from "../../../components/inputs";
import BlueButton from "../../../components/buttons/BlueButton";
import { validators } from "../../../constants";
import InputValidator from "../../../components/inputs/InputValidator";

const AccountDetailsForm = ({ navigate, nextStep, formChangeHandler }) => {
  const { handleSubmit, errors, control } = useForm();
  const onSubmit = async (data) => {
    try {
      await formChangeHandler(data, 'account');
      nextStep();
    } catch (error) {
      console.log(error);
    }
  };

  const genders = [
    { value: "Male", label: "Male", id: 1 },
    { value: "Female", label: "Female", id: 2 },
    { value: "None", label: "Prefer not to say", id: 3 },
  ];

  return (
    <div className="flex justify-center mt-12">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid justify-items-center">
          <div>
            <HeadingOne className="text-tripplo-200 font-medium text-center mt-4 mb-4">
              Create Account
            </HeadingOne>
            <AuthForm height="550px" active={true}>
              <div className="flex flex-col p-2 ">
                <BodyOne className="mb-2" isRequired={true}>Name</BodyOne>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ onChange, value }) => (
                    <Input size="large" placeholder="Enter your first name here" onChange={onChange} value={value} />
                  )}
                />
                {errors.name && (
                  <BodyOne className="text-red-600">
                    Please enter your name
                  </BodyOne>
                )}
              </div>
              <div className="flex flex-col p-2 ">
                <BodyOne className="mb-2" isRequired={true}>Last Name</BodyOne>
                <Controller
                  name="family_name"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ onChange, value }) => (
                    <Input size="large" placeholder="Enter your last name here" onChange={onChange} value={value} />
                  )}
                />
                {errors.family_name && (
                  <BodyOne className="text-red-600">
                    Please enter your last name
                  </BodyOne>
                )}{" "}
              </div>
              <div className="flex flex-col p-2 ">
                <BodyOne className="mb-2" isRequired={true}>Email address</BodyOne>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ onChange, value }) => (
                    <Input size="large" placeholder="Enter your email address here" onChange={onChange} value={value} />
                  )}
                />
                {errors.email && (
                  <BodyOne className="text-red-600">
                    Please enter your email
                  </BodyOne>
                )}
              </div>

              <div className="flex flex-col p-2 ">
                <BodyOne className="mb-2" isRequired={true}>Password</BodyOne>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{ required: true, validate: (value) => validators.isPasswordValid(value)}}
                  render={({ onChange, value}) => (
                    <InputValidator
                      validatorStates={validators.getPasswordValidatorStates(value)}
                      iconTitles={["8+ Letters", "1 Uppercase", "1 Number"]}>
                      <Input.Password style={{border: "1px solid transparent"}} size="large" placeholder="Enter your password here" onChange={onChange} value={value} />
                    </InputValidator>
                  )}
                />
                {errors.password && (
                  <BodyOne className="text-red-600">
                    {errors.password.message}
                  </BodyOne>
                )}
              </div>

              <div className="flex flex-col p-2 ">
                <BodyOne className="mb-2" isRequired={true}>Mobile number</BodyOne>
                <Controller
                  name="phone_number"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ onChange, value }) => (
                    <CelluarInput
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                {errors.phone_number && (
                  <BodyOne className="text-red-600">
                    Please enter your mobile number
                  </BodyOne>
                )}
              </div>

              <div className="flex flex-col p-2">
                <BodyOne className="mb-2">Gender</BodyOne>

                <div className="flex justify-between">
                  <Controller
                    name="gender"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ onChange, value }) => (
                      <>
                        {genders.map((gender) => (
                          <div key={gender.id} className="flex flex-row">
                            <CheckBox
                              className="mt-1 mr-2"
                              name={gender.value}
                              onChange={() => onChange(gender.value)}
                              value={value}
                            />
                            <span>{gender.label}</span>
                          </div>
                        ))}
                      </>
                    )}
                  />
                </div>
              </div>
            </AuthForm>
          </div>
          <div className="flex flex-col mt-4">
            <BodyOne className="text-center my-4">
              By continuing you agree to our <Link className="text-tripplo-200 hover:text-tripplo-200" to="/legal/terms-of-use">Terms of Service</Link> and <Link className="text-tripplo-200 hover:text-tripplo-200" to="/legal/privacy-policy">Privacy Policy</Link>
            </BodyOne>

            <BlueButton
              className="w-11/12 ml-3 mt-4"
              type="submit"
            //isLoading={}
            >
              Proceed
            </BlueButton>

            <BodyOne className="text-center mt-2">
              Already have an account?{" "}
              <span
                className="text-tripplo-200 font-medium cursor-pointer"
                onClick={() => navigate("/login/mobile-app/MzRlNTgzNGNkMzVka3J2anAwcGpkanAxYmM=")}
              >
                Login
              </span>
            </BodyOne>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AccountDetailsForm;
