import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Error } from "../../components/feedback";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "../../atoms";
import { useForm, Controller } from "react-hook-form";
import AuthForm from "../../components/forms/AuthForm";
import { BodyOne, HeadingOne } from "../../components/typography";
import { Input } from "../../components/inputs";
import BlueButton from "../../components/buttons/BlueButton";

const ConfirmSignUpCode = (props) => {
  const userData = useRecoilValue(userState);
  const setUserState = useSetRecoilState(userState);
  const [isLoading, setIsLoading] = useState(false);

  console.log("userData", userData);

  const { handleSubmit, errors, control } = useForm();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await Auth.confirmSignUp(userData.email, data.code);
      const user = await Auth.signIn(userData.email, userData.password);

      console.log("ttt", user);
      
      const accessToken = user.signInUserSession.idToken.jwtToken;
      const sub = user.attributes.sub;
      const stateCopy = {
        ...userData,
        ...user,
      };

      console.log("stateCopy", stateCopy);

      setUserState((usersState) => ({
        ...usersState,
        accessToken,
        sub,
      }));

      props.setActiveKey("CompanyForm");
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);

      Error({
        message: "Something went wrong",
        description: e.message,
      });
    }
  };

  return (
    <div className=" flex justify-center ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid justify-items-center">
          <div>
            <HeadingOne className="text-tripplo-200 font-medium text-center mt-4 mb-4">
              Verification code
            </HeadingOne>
            <AuthForm height="150px" active="Signup">
              <div className="flex flex-col p-2 mb-3">
                <BodyOne className="mb-2 text-center">
                  Please enter your 6 digit verification code here
                </BodyOne>
                <Controller
                  name="code"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ onChange, value }) => (
                    <Input onChange={onChange} value={value} />
                  )}
                />
                {errors.code && (
                  <BodyOne className="text-red-600">
                    Please enter your code
                  </BodyOne>
                )}{" "}
              </div>
            </AuthForm>
          </div>
          <div className="flex flex-col w-full mt-10">
            <BlueButton
              className="w-full "
              type="submit"
              onClick={() => onSubmit}
              isLoading={isLoading}
            >
              Continue
            </BlueButton>

            <BodyOne className="text-center mt-2 text-tripplo-200 font-medium cursor-pointer">
              Resend Code
            </BodyOne>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ConfirmSignUpCode;
