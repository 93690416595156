import React from "react";
import PropTypes from "prop-types";

const requiredSymbol = () => {
  return (
    <span className="text-red-600">*</span>
  )
}

const BodyOne = ({ className, children, isRequired=false }) => {
  return (
    <p className={`${className} text-sm text-black text-bold font-poppins`}>
      { children } { isRequired ? requiredSymbol() : '' }
    </p>
  );
};

BodyOne.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

BodyOne.defaultProps = {
  className: "",
};
export default BodyOne;
