import React, { useState } from "react";
import AuthForm from "../../components/forms/AuthForm";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { onboardingState } from "../../atoms";
import { useForm, Controller } from "react-hook-form";
import { BodyOne, HeadingOne } from "../../components/typography";
import { Input } from "../../components/inputs";
import BlueButton from "../../components/buttons/BlueButton";
import { RemoveIcon } from "../../assets/icons";
const DriversForm = (props) => {
  const onboardingData = useRecoilValue(onboardingState);
  const setOnboardingState = useSetRecoilState(onboardingState);
  const { handleSubmit, errors, control } = useForm();

  console.log("onboardingData-drivers", onboardingData);
  const [formDrivers, setFormDrivers] = useState([]);

  const removeDriver = (index) => {
    const t = [...formDrivers];
    t.splice(index, 1);
    setFormDrivers(t);
  };

  const updateDriver = ({ index, field, value }) => {
    const t = [...formDrivers];
    t[index][field] = value;
    setFormDrivers(t);
  };
  const addDriver = () => {
    const t = [...formDrivers];
    t.push({
      first_name: null,
      last_name: null,
    });
    setFormDrivers(t);
  };
  const onSubmit = (data) => {
    setOnboardingState((onboardingState) => ({
      ...onboardingState.surveyData,
      fleet: {
        trucks: onboardingState.fleet.trucks,
        drivers: formDrivers,
      },
    }));
    props.setActiveKey("TrailersForm");
  };
  const inputs = formDrivers.map((t, index) => {
    const count = index + 1;
    return (
      <AuthForm height="350px" active="Signup" key={index}>
        <div className="flex justify-between p-4">
          <p className="font-poppins font-semibold text-blue-500 ">
            Driver {count}
          </p>
          <span onClick={() => removeDriver(index)} className="flex flex-row">
            <RemoveIcon /> Remove{" "}
          </span>
        </div>

        <div className="grid grid-cols-2">
          <div className="flex flex-col p-2 mb-3">
            <BodyOne className="mb-2 ">First name</BodyOne>
            <Controller
              name={`firstName.${index}`}
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={(e) =>
                    updateDriver({
                      index,
                      field: "first_name",
                      value: e.currentTarget.value,
                    })
                  }
                  value={value}
                />
              )}
            />
            {errors.firstName && (
              <BodyOne className="text-red-600">
                Please enter your drivers first name
              </BodyOne>
            )}{" "}
          </div>
          <div className="flex flex-col p-2 mb-3">
            <BodyOne className="mb-2 ">Surname </BodyOne>
            <Controller
              name={`lastName.${index}`}
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={(e) =>
                    updateDriver({
                      index,
                      field: "last_name",
                      value: e.currentTarget.value,
                    })
                  }
                  value={value}
                />
              )}
            />
            {errors.lastName && (
              <BodyOne className="text-red-600">
                Please enter your drivers surnname
              </BodyOne>
            )}{" "}
          </div>
        </div>
      </AuthForm>
    );
  });
  return (
    <div className=" flex justify-center ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid justify-items-center">
          <div>
            <HeadingOne className="text-tripplo-200 font-semibold text-center mt-4 mb-4">
              My drivers
            </HeadingOne>
            {inputs}
            <BlueButton onClick={() => addDriver()}>+</BlueButton>
          </div>
          <div className="flex justify-between  mt-10">
            <BlueButton
              className=""
              onClick={() => props.setActiveKey("TrucksFrom")}
              // isLoading={loading}
            >
              Previous
            </BlueButton>

            <BlueButton
              type="submit"
              // isLoading={loading}
            >
              Next
            </BlueButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DriversForm;
