// Package Imports
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Input } from "antd";

// Components
import AuthForm from "../../../components/forms/AuthForm";
import { BodyOne, HeadingOne } from "../../../components/typography";
import BlueButton from "../../../components/buttons/BlueButton";
import CountrySelector from "../../../components/inputs/CountrySelector";

const CompanyDetailsForm = ({ navigate, nextStep, prevStep, formChangeHandler }) => {
    const { handleSubmit, errors, control } = useForm();
    const onSubmit = async (data) => {
        try {
            await formChangeHandler(data, 'company');
            nextStep();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="flex justify-center mt-12">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid justify-items-center">
                    <div>
                        <HeadingOne className="text-tripplo-200 font-medium text-center mt-4 mb-4">
                            Company Details
                        </HeadingOne>
                        <AuthForm height="290px" active={true}>
                            <div className="flex flex-col p-2 ">
                                <BodyOne className="mb-2" isRequired={true}>Registered Name</BodyOne>
                                <Controller
                                    name="org_name"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ onChange, value }) => (
                                        <Input size="large" placeholder="Enter your registered company name here" onChange={onChange} value={value} />
                                    )}
                                />
                                {errors.org_name && (
                                    <BodyOne className="text-red-600">
                                        Please enter your registered company name
                                    </BodyOne>
                                )}
                            </div>
                            <div className="flex flex-col p-2 ">
                                <BodyOne className="mb-2" isRequired={true}>Trading Name</BodyOne>
                                <Controller
                                    name="org_trading_name"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ onChange, value }) => (
                                        <Input size="large" placeholder="Enter your company trading name here" onChange={onChange} value={value} />
                                    )}
                                />
                                {errors.org_trading_name && (
                                    <BodyOne className="text-red-600">
                                        Please enter your company trading name
                                    </BodyOne>
                                )}{" "}
                            </div>
                            <div className="flex flex-col p-2 ">
                                <BodyOne className="mb-2" isRequired={true}>Country of Registration</BodyOne>
                                <Controller
                                    name="registration_country"
                                    control={control}
                                    defaultValue="South Africa"
                                    rules={{ required: true }}
                                    render={({ onChange, value }) => (
                                        <CountrySelector onChange={onChange} value={value} />
                                    )}
                                />
                                {errors.registration_country && (
                                    <BodyOne className="text-red-600">
                                        Please enter your country of registration
                                    </BodyOne>
                                )}
                            </div>
                        </AuthForm>
                    </div>
                    <div className="flex flex-col my-4">
                        <BodyOne className="text-center mt-2">
                            By continuing you agree to our Terms of Service and Privacy Policy
                        </BodyOne>

                        <div className="flex flex-row flex-nowrap my-4">
                            <BlueButton
                                className="w-11/12 ml-3"
                                type="submit"
                            >
                                Proceed
                            </BlueButton>
                            <BlueButton
                                className="w-11/12 ml-3"
                                type="button"
                                onClick={() => prevStep()}
                            >
                                Back
                            </BlueButton>
                        </div>

                        <BodyOne className="text-center mt-2">
                            Already have an account?{" "}
                            <span
                                className="text-tripplo-200 font-medium cursor-pointer"
                                onClick={() => navigate("/login/mobile-app/MzRlNTgzNGNkMzVka3J2anAwcGpkanAxYmM=")}
                            >
                                Login
                            </span>
                        </BodyOne>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CompanyDetailsForm;
