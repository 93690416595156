import { notification } from "antd";

export const Success = ({ message, description }) =>
  notification.success({
    message: message,
    description: description,
  });

export const Error = ({ message, description }) =>
  notification.error({
    message: message,
    description: description,
  });
