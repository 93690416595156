// Package Imports
import React from "react";

// Internal Imports
import AccountDetailsForm from "./signupSteps/AccountDetailsForm";
import CompanyDetailsForm from "./signupSteps/CompanyDetailsForm";
import AccountTypeForm from "./signupSteps/AccountTypeForm";
import { useLocalStorage, mergeObjects } from "../../utils/helpers";

const SignUpForm = (props) => {
  const { navigate } = props.props;

  // eslint-disable-next-line
  const [signupState, setSignUpState] = useLocalStorage("signupState", []);
  const [stepState, setStepState] = useLocalStorage("stepState", 1);

  // Route user to next step in Sign-Up form
  const nextStep = () => {
    setStepState(stepState + 1);
  };

  // Route user to next step in Sign-Up form
  const prevStep = () => {
    setStepState(stepState - 1);
  };

  // Update form state based on multi-step user input
  const formChangeHandler = async (formData, formKey) => {
    if (formKey === "account") {
      setSignUpState(formData);
    } else if (formKey === "company") {
      const data = mergeObjects("signupState", formData);
      setSignUpState(data);
    } else if (formKey === "user") {
      const data = mergeObjects("signupState", formData);
      setSignUpState(data);
    } else {
      console.log("ERROR: Invalid Key Supplied");
    }
  };

  const renderForms = () => {
    let step = stepState;

    switch (step) {
      case 1:
        window.localStorage.clear();
        return (
          <AccountDetailsForm
            navigate={navigate}
            nextStep={nextStep}
            formChangeHandler={formChangeHandler}
          />
        );
      case 2:
        return (
          <CompanyDetailsForm
            navigate={navigate}
            nextStep={nextStep}
            prevStep={prevStep}
            formChangeHandler={formChangeHandler}
          />
        );
      case 3:
        return (
          <AccountTypeForm
            navigate={navigate}
            prevStep={prevStep}
            setStepState={setStepState}
            formChangeHandler={formChangeHandler}
          />
        );
      default:
        window.localStorage.clear();
        return (
          <AccountDetailsForm
            navigate={navigate}
            nextStep={nextStep}
            formChangeHandler={formChangeHandler}
          />
        );
    }
  };

  return <>{renderForms()}</>;
};

export default SignUpForm;
