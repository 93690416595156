import React from "react";
import { Router } from "@reach/router";
import Home from "./pages/index";
import Login from "./pages/Login";
import LoginSuccess from "./pages/LoginSuccess";
import SignUp from "./pages/signUp";
import SignUpSuccess from "./pages/signUp/SignUpSuccess";
import Legal from "./pages/Legal";

/*

Route Patterns
==============

1. Mobile App Login - /login/mobile-app/{cognito-userpool-client-id}
2. Mobile Post-Auth Redirect [JWT Token]- /login-redirect/tripplo-mobile-auth/{jwt-token}
3. Mobile App Signup - /signup/mobile-app

*/

const Routes = ({ props }) => {
  return (
    <Router>
      <Home path="/" props={props} />
      <Login path="/login/:serviceURL/:clientId" props={props} />
      <LoginSuccess path="/login-redirect/:serviceURL/:token" props={props} />
      <SignUp path="/signup/:serviceURL" props={props} />
      <SignUpSuccess path="/signup-redirect/:serviceURL/:token" props={props} />
      <Legal path="/legal/:legalDocument" props={props} />
    </Router>
  );
};

export default Routes;
