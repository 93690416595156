import gql from "graphql-tag";

export const LOGIN = gql`
  mutation LOGIN($email: String!, $password: String!, $clientID: String!) {
    login(email: $email, password: $password, clientID: $clientID) {
      accessToken {
        jwt
        expiry
      }
      refreshToken
    }
  }
`;

export const SIGN_UP = gql`
	mutation SIGN_UP($SignUpInput: SignUpInput!) {
		signUp(signup: $SignUpInput) {
			text
		}
	}
`;
