import React from "react";
import { useMemo } from "react"
import PropTypes from "prop-types";
import { successIcon } from "../../assets/icons";

const DefaultValidatorSkin = ({iconTitle, iconState}) => {
    return (
        <div className="flex items-center">
            {successIcon(iconState)}
            <span className="ml-6 text-gray-800">{iconTitle}</span>
        </div>
    )
}
const InputValidator = ({items, iconTitles, onRenderItem, validatorStates, ...props}) => {

    const viewIons = useMemo(() => {
        return validatorStates.map((state, index) => (
            <div key={index}>
                {onRenderItem 
                ? onRenderItem(items[index], state) 
                :  <DefaultValidatorSkin iconTitle={iconTitles[index]} iconState={state} />}
            </div>
        )); 
    }, [validatorStates, items, iconTitles, onRenderItem]);
    return (
    <div className="flex flex-col border-sky-200 rounded border">
        {props.children}
        <div className="pb-2 flex justify-evenly">
            {viewIons}
        </div>
    </div>)
}

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.exact({
        title: PropTypes.string,
        icon: PropTypes.node
    })).isRequired,
    iconTitles: PropTypes.string,
    onRenderItem: PropTypes.func,
    validatorStates: PropTypes.arrayOf(PropTypes.bool).isRequired,
}

InputValidator.propTypes = propTypes;

export default InputValidator;