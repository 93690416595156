import React from "react";
import styled from "styled-components";
import { AutoComplete } from "antd";
import "antd/dist/antd.css";

const Autocomplete = ({ options, onChange, onSelect }) => {
  const AutoCompleteStyles = styled(AutoComplete)`
    // &.ant-select-single:not(&.ant-select-customize-input),
    // &.ant-select-selector {
    //   padding: 1.5rem !important;
    // }
  `;
  return (
    <AutoCompleteStyles
      options={options}
      onChange={onChange}
      filterOption={(inputValue, option) =>
        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }
      onSelect={onSelect}
    />
  );
};

export default Autocomplete;
