import React, { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { myCompanyClient } from "../../graphql/clients";
import { CHECK_IF_COMPANY_EXISTS } from "../../graphql/Queries";
import AuthForm from "../../components/forms/AuthForm";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useForm, Controller } from "react-hook-form";
import { BodyOne, HeadingOne } from "../../components/typography";
import { Input, Dropdown } from "../../components/inputs";
import BlueButton from "../../components/buttons/BlueButton";
import { countries } from "../../constants";
import { userState } from "../../atoms";
import { Success, Error } from "../../components/feedback";
import * as baseApi from "../../services/baseApi";

const CompanyForm = (props) => {
  const userData = useRecoilValue(userState);
  const setUserState = useSetRecoilState(userState);
  const [isLoading, setIsLoading] = useState(false);
  console.log("userData-corp", userData);
  const { handleSubmit, errors, control } = useForm();
  const [checkName, { data }] = useLazyQuery(CHECK_IF_COMPANY_EXISTS, {
    client: myCompanyClient,
  });

  if (
    data &&
    (data.checkIfCompanyExists.status === "900" ||
      data.checkIfCompanyExists.status === "902")
  ) {
    console.log("data", data);

    Error({
      description: data.checkIfCompanyExists.description,
      message: "Please choose another name",
    });
  }

  const checkIfTradingExists = async (name, check) => {
    await checkName({
      variables: {
        companyName: name,
        checkType: check,
      },
    });
  };
  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      console.log("corp", data);
      const payload = {
        org_name: data.registeredName,
        org_trading_name: data.tradingName,
        registration_country: data.country,
      };

      const result = await baseApi.post({
        url: "organisations",
        payload: payload,
        token: userData.accessToken,
      });

      setUserState((usersState) => ({
        ...usersState,
        org_name: data.registeredName,
        org_trading_name: data.tradingName,
        registration_country: data.country,
        org_id: result.data.org_id,
      }));
      Success({
        message: "Sucess",
        description: "Your company has been registered",
      });
      setIsLoading(false);

      props.setActiveKey("OrganistationForm");
    } catch (e) {
      setIsLoading(false);

      Error({
        message: "Something went wrong",
        description: e.message,
      });
    }
  };
  return (
    <div className=" flex justify-center ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid justify-items-center">
          <div>
            <HeadingOne className="text-tripplo-200 font-medium text-center mt-4 mb-4">
              Company details
            </HeadingOne>
            <AuthForm height="350px" active="Signup">
              <div className="flex flex-col p-2 mb-3">
                <BodyOne className="mb-2 ">Registered name </BodyOne>
                <Controller
                  name="registeredName"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ onChange, value }) => (
                    <Input
                      onChange={onChange}
                      value={value}
                      onBlur={(e) =>
                        checkIfTradingExists(
                          e.currentTarget.value.trim(),
                          "name"
                        )
                      }
                    />
                  )}
                />
                {errors.registeredName && (
                  <BodyOne className="text-red-600">
                    Please enter your company's registered name
                  </BodyOne>
                )}
              </div>
              <div className="flex flex-col p-2 mb-3">
                <BodyOne className="mb-2 ">Trading name </BodyOne>
                <Controller
                  name="tradingName"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ onChange, value }) => (
                    <Input
                      onChange={onChange}
                      value={value}
                      onBlur={(e) =>
                        checkIfTradingExists(
                          e.currentTarget.value.trim(),
                          "name"
                        )
                      }
                    />
                  )}
                />
                {errors.tradingName && (
                  <BodyOne className="text-red-600">
                    Please enter your company's trading name
                  </BodyOne>
                )}
              </div>
              <div className="flex flex-col p-2 mb-3">
                <BodyOne className="mb-2 ">Country of registration </BodyOne>
                <Controller
                  name="country"
                  control={control}
                  defaultValue={countries[0].country}
                  rules={{ required: true }}
                  render={({ onChange, value }) => (
                    <Dropdown onChange={onChange} value={value}>
                      {countries.map((country, index) => (
                        <option
                          key={index}
                          value={country.country}
                          defaultValue={country.country}
                        >
                          {country.flag} {country.country}
                        </option>
                      ))}
                    </Dropdown>
                  )}
                />
                {errors.tradingName && (
                  <BodyOne className="text-red-600">
                    Please select where your company trades
                  </BodyOne>
                )}
              </div>
            </AuthForm>
          </div>
          <div className="flex flex-col w-full mt-10">
            <BlueButton
              className="w-full "
              type="submit"
              // onClick={() => props.setActiveKey("OrganistationForm")}
              isLoading={isLoading}
            >
              Submit
            </BlueButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CompanyForm;
