import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate } from "@reach/router";
import { onboardingState, userState } from "../../atoms";
import { BodyOne, HeadingOne } from "../../components/typography";
import BlueButton from "../../components/buttons/BlueButton";
import AuthForm from "../../components/forms/AuthForm";
import { CheckBox } from "../../components/inputs";
import { countries } from "../../constants";
import { Success, Error } from "../../components/feedback";
import * as baseApi from "../../services/baseApi";
import { addOrgUserType } from "../../services/organisations";

const RoutesForm = (props) => {
  const onboardingData = useRecoilValue(onboardingState);
  const userData = useRecoilValue(userState);
  const [isLoading, setIsLoading] = useState(false);
  // const setOnboardingState = useSetRecoilState(onboardingState);
  const [formRoutes, setFormRoutes] = useState([]);
  const navigate = useNavigate();

  // const trucks = onboardingData.fleet.trucks;
  // const trailers = onboardingData.fleet.trailers;
  // const drivers = onboardingData.fleet.drivers;
  console.log("onboardingData-routes", userData);

  const addRoutes = (e) => {
    const r = [...formRoutes];
    if (r.includes(e)) {
      const filtered = r.filter((t) => t !== e);
      setFormRoutes(filtered);
    } else {
      r.push(e);
      setFormRoutes(r);
    }
  };

  //add trucks
  // const uploadTrucks = async (trucks) => {
  //   try {
  //     trucks.map(async (t) => {
  //       const values = {
  //         asset_status: "Active",
  //         asset_type: "truck",
  //         internal_asset_no: "TEST",
  //         statutory_details: { ...t },
  //         org_id: userData.org_id,
  //       };

  //       await baseApi
  //         .post({
  //           url: "assets/trucks",
  //           payload: values,
  //         })
  //         .then(() =>
  //           Success({
  //             message: "We have succcessfully added your truck",
  //           })
  //         );
  //     });
  //   } catch (e) {
  //     Error({
  //       message: "Unable to add your trucks",
  //       description:
  //         "Please try again or make sure your truck's details are correct",
  //     });
  //   }
  // };
  //add drivers
  // const uploadDrivers = async (drivers) => {
  //   try {
  //     drivers.map(async (t) => {
  //       const values = {
  //         statutory_details: t,
  //         member_status: "Active",
  //         org_role: "driver",
  //       };

  //       await baseApi
  //         .post({
  //           url: `organisations/${userData.org_id}/members/${uuidv4()}`,
  //           payload: values,
  //         })
  //         .then(() =>
  //           Success({
  //             message: "We have succcessfully added your driver",
  //           })
  //         );
  //     });
  //   } catch (e) {
  //     Error({
  //       message: "Unable to add your trucks",
  //       description:
  //         "Please try again or make sure your truck's details are correct",
  //     });
  //   }
  // };

  //add trailers
  // const uploadTrailers = async (trailers) => {
  //   try {
  //     trailers.map(async (t) => {
  //       const values = {
  //         asset_status: "Active",
  //         internal_asset_no: "TEST",
  //         asset_type: "trailer",
  //         statutory_details: { ...t },
  //         org_id: userData.org_id,
  //       };

  //       await baseApi
  //         .post({
  //           url: "assets/trailers",
  //           payload: values,
  //         })
  //         .then(() =>
  //           Success({
  //             message: "We have succcessfully added your trailer",
  //           })
  //         );
  //     });
  //   } catch (e) {
  //     Error({
  //       message: "Unable to add your trailers",
  //       description:
  //         "Please try again or make sure your truck's details are correct",
  //     });
  //   }
  // };
  console.log("formRoutes", formRoutes);

  const onNext = async () => {
    try {
      if (formRoutes.length === 0) {
        Error({
          message: "Opps, thats not good",
          description: "Please select at least one route",
        });
      } else {
        setIsLoading(true);

        // if (trucks.length !== 0) {
        //   await uploadTrucks(trucks);
        // }

        // if (drivers.length !== 0) {
        //   await uploadDrivers(drivers);
        // }

        // if (trailers.length !== 0) {
        //   await uploadTrailers(trailers);
        // }

        // const fleetLength = {
        //   trucks: trucks.length,
        //   trailers: trailers.length,
        //   drivers: drivers.length,
        // };
        const surveyData = {
          fleet: { ...onboardingData.surveyData.fleet },
          routes: formRoutes,
          org_id: userData.org_id,
        };
        let sessionData = { ...userData };
        delete sessionData.password;
        const payloadObject = {
          fleetLength: {
            trucks: "0",
            trailers: "0",
            drivers: "0",
          },
          surveyData: surveyData,
          user_type: userData.userType,
          ...sessionData,
        };
        await addOrgUserType(payloadObject);
        await baseApi.post({
          url: "my-company/addSurveyData",
          payload: surveyData,
        });

        setIsLoading(false);
        navigate(`/signup-rediect/${userData.accessToken}`);
        Success({
          message: "Success, you have created an account! 🎉",
        });
      }
    } catch (e) {
      setIsLoading(false);
      Error({
        message: "Something went wrong",
        description: e.message,
      });
    }
  };
  return (
    <div className=" flex justify-center ">
      <div className="grid justify-items-center">
        <div>
          <HeadingOne className="text-tripplo-200 font-semibold text-center mt-4 mb-4">
            Type of organistation
          </HeadingOne>
          <AuthForm height="980px" active="Signup">
            <BodyOne className="text-base text-center">
              Please select the coutries you're interested in
            </BodyOne>
            {countries.map((country, index) => (
              <div className="flex justify-between">
                <BodyOne className="text-base text-center mb-8">
                  {country.country}{" "}
                </BodyOne>
                <CheckBox
                  value={country.country}
                  onClick={() => addRoutes(country.country)}
                />
              </div>
            ))}
          </AuthForm>
        </div>
        <div className="flex justify-between  mt-10">
          <BlueButton
            className=""
            onClick={() => props.setActiveKey("TrailersForm")}
            // isLoading={loading}
          >
            Previous
          </BlueButton>

          <BlueButton
            className=""
            onClick={() => onNext()}
            isLoading={isLoading}
          >
            Next
          </BlueButton>
        </div>
      </div>
    </div>
  );
};
export default RoutesForm;
