// Package Imports
import React from "react";
import { useParams } from "@reach/router";

const Legal = () => {
  const params = useParams();

  const renderLegals = () => {
    switch(params.legalDocument) {
      case "terms-of-use":
        window.location.href = "https://www.tripplo.co/terms-of-use";
        break
      case "privacy-policy":
        window.location.href = "https://www.tripplo.co/privacy-policy";
        break
      default:
        break
    }
  }

  return (
    <>
      { renderLegals() }
    </>
  )
}

export default Legal;