// Package Imports
import React, { useEffect } from "react";

// Components
import { BodyOne, HeadingOne } from "../../components/typography";
import AuthForm from "../../components/forms/AuthForm";

const SignUpSuccess = () => {
  useEffect(() => {
    window.localStorage.clear();
  });

  return (
    <div className="flex justify-center mt-12">
      <div className="grid grid-rows-2 place-content-between gap-20">
        <div>
          <HeadingOne className="text-tripplo-200 text-center mt-4 mb-4">
            Sign-Up Successful
          </HeadingOne>
          <AuthForm height="235px" active={true}>
            <div className="flex flex-col p-2 mb-3">
              <div className="flex flex-col text-center">
                <BodyOne className="mb-2">You have successfully created your account, please wait...</BodyOne>
              </div>
            </div>
          </AuthForm>
        </div>
      </div>
    </div>
  );
}

export default SignUpSuccess;