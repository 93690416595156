// Package Imports
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";

// Internal Imports
import AuthForm from "../../../components/forms/AuthForm";
import { BodyOne, HeadingOne } from "../../../components/typography";
import BlueButton from "../../../components/buttons/BlueButton";
import AccountTypeBlock from "../../../components/buttons/AccountTypeBlock";
import { Error } from "../../../components/feedback";
import { SIGN_UP } from "../../../graphql/Mutations";
import {
  cognitoDetails,
  userSignUp,
  userLogin,
  check_existing_organization,
  assign_user_to_usertype_group,
  create_new_user_organization,
} from "../../../services/AuthActions";
import { message } from "antd";
import { UserException } from "../../../utils/helpers";

const AccountTypeForm = ({
  navigate,
  prevStep,
  formChangeHandler,
  setStepState,
}) => {
  const { handleSubmit, errors, control } = useForm();
  const [loadingState, setLoadingState] = useState(false);

  const [mutateUserSignUp] = useMutation(SIGN_UP, {
    onCompleted: (data) => {
      if (data !== undefined || data.signUp !== null) {
        message.success("Sign-Up Successful");
      } else {
        message.error(" Could not create account");
        throw new UserException("Sign-Up Failed");
      }
    },
  });

  // Post-SignUp Flow Handler
  const postSignUp = async (formData) => {
    const loginData = await userLogin(formData);
    const signUpState = await JSON.parse(
      window.localStorage.getItem("signupState")
    );

    if (loginData !== undefined) {
      const userData = JSON.stringify({
        token: loginData.login.accessToken.jwt,
        refresh: loginData.login.refreshToken,
        sub: signUpState.sub,
        org_id: signUpState.org_id,
      });

      navigate(`/signup-redirect/tripplo-mobile-auth/${userData}`);
    } else {
      message.error("ERROR: Could not sign-in");
    }
  };

  const orgExistingCheck = async (formData) => {
    const companyCheck = await check_existing_organization(formData);

    // Status 902 === Organization Exists
    // Status 903 === Organization Does-Not Exist
    if (companyCheck.status !== "903") {
      message.error(companyCheck.description);
      throw new UserException(companyCheck.description);
    }
  };

  // MAIN - Sign-Up Flow
  const userAuth = async (formData) => {
    try {
      // Check if org exists
      await orgExistingCheck(formData);

      // Create Cognito User
      await userSignUp(mutateUserSignUp, formData);

      // Login Cognito User & Set Data to State
      const loginData = await userLogin(formData);

      // Add login data to state
      await formChangeHandler(
        {
          jwt: loginData.login.accessToken.jwt,
        },
        "user"
      );

      // Grab Latest Updated State
      let updatedState = await JSON.parse(
        window.localStorage.getItem("signupState")
      );

      // Return Object | Cognito Username & Sub
      const cognitoDetailsObject = await cognitoDetails(updatedState);

      // Merge Cognito Details with Current State
      updatedState = await Object.assign(updatedState, cognitoDetailsObject);
      console.log(updatedState);

      // Add Cognito Details to Global State
      await formChangeHandler(cognitoDetailsObject, "user");

      // Add user to Cognito Group
      const user_group_add = await assign_user_to_usertype_group(updatedState);

      // IF - User was added to Cognito Group
      if (user_group_add === "success") {
        // Create New Ogranization & Return Data | Organization ID, Created/Updated Timestamps
        let createdOrgData = await create_new_user_organization(updatedState);

        // Re-Construct Data Object & Only Include Required Data
        createdOrgData = {
          org_id: createdOrgData.org_id,
          created_timestamp: createdOrgData.created_timestamp,
          updated_timestamp: createdOrgData.updated_timestamp,
        };

        // Add OrgData to Global State
        await formChangeHandler(createdOrgData, "user");
        // Sign-Up Flow Complete
        message.success("You're All Setup, Woohoo...");
      }
    } catch (e) {
      setLoadingState(false);
      message.error("Account Setup Process Failed");
      console.log(e);
      setStepState(1);
    }
  };

  const onSubmit = async (formData) => {
    // The try/catch block is used to limit execution if an issue is encountered mid-flow
    // Therefore catch statements aren't used in the functions that are invoked within the block
    // Minor exceptions for request/graphQL handlers
    try {
      setLoadingState(true);

      // Add User_Type to Global State
      await formChangeHandler(formData, "user");

      // Store Global State in Object
      let signupFormState = await JSON.parse(
        window.localStorage.getItem("signupState")
      );

      // Invoke Sign-Up Flow here
      await userAuth(signupFormState);

      // Invoke Post-Sign-Up Flow
      await postSignUp(signupFormState);

      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
      Error({
        message: "Something went wrong...",
        description: "Please contact our support services",
      });
      throw new UserException("SignUp Process Failed");
    }
  };

  return (
    <div className="flex justify-center mt-12">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid justify-items-center">
          <div>
            <HeadingOne className="text-tripplo-200 font-medium text-center mt-4 mb-4">
              Type of Organization
            </HeadingOne>
            <AuthForm height="290px" active={true}>
              <div className="flex flex-col p-2 ">
                <Controller
                  name="user_type"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ onChange, value }) => (
                    <AccountTypeBlock onChange={onChange} value={value} />
                  )}
                />
                {errors.user_type && (
                  <BodyOne className="text-red-600">
                    Please select an organization type
                  </BodyOne>
                )}
              </div>
            </AuthForm>
          </div>
          <div className="flex flex-col my-4">
            <BodyOne className="text-center mt-2">
              By continuing you agree to our Terms of Service and Privacy Policy
            </BodyOne>

            <div className="flex flex-row flex-nowrap my-4">
              <BlueButton
                className="w-11/12 ml-3"
                type="submit"
                isLoading={loadingState}
              >
                Proceed
              </BlueButton>
              <BlueButton
                className="w-11/12 ml-3"
                type="button"
                onClick={() => prevStep()}
              >
                Back
              </BlueButton>
            </div>

            <BodyOne className="text-center mt-2">
              Already have an account?{" "}
              <span
                className="text-tripplo-200 font-medium cursor-pointer"
                onClick={() =>
                  navigate(
                    "/login/mobile-app/MzRlNTgzNGNkMzVka3J2anAwcGpkanAxYmM="
                  )
                }
              >
                Login
              </span>
            </BodyOne>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AccountTypeForm;
