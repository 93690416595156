import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";

const BlueButton = ({
  className = "",
  onClick,
  children,
  disabled = false,
  isLoading,
  large,
  long,
  ...props
}) => {
  return (
    <button
      /* eslint-disable no-template-curly-in-string*/
      className={`${className} cursor-pointer bg-tripplo-200  text-white focus:outline-none font-poppins rounded-lg px-16 py-2 hover:bg-white hover:text-tripplo-200`}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {isLoading ? <Spin /> : children}
    </button>
  );
};
const propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.string,
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  long: PropTypes.bool,
  isLoading: PropTypes.bool,
};
BlueButton.propTypes = propTypes;
export default BlueButton;
