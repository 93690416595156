import React from "react";
import PropTypes from "prop-types";

const Dropdown = ({ className = "", children, ...props }) => {
  return (
    <select
      className={`${className}    bg-tripplo-600 
  rounded-lg 
  py-3
  px-4 
  border
  border-tripplo-300
  focus:outline-none 
  focus:bg-white`}
      {...props}
    >
      {children}
    </select>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Dropdown;
