import React from "react";
import PropTypes from "prop-types";

const AuthForm = ({ children, height }) => {
  return (
    <div
      className="bg-tripplo-500 rounded-lg p-2"
      style={{
        display: "flex",
        flexDirection: "column",
        width: "90vw"
      }}
    >
      {children}
    </div>
  );
};

AuthForm.propTypes = {
  active: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default AuthForm;
