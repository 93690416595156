// Package Imports
import { Select } from "antd";
import React from "react";

// Components
import { countries } from "../../constants";

const { Option } = Select;

const CountrySelector = ({ onChange }) => {
    return (
        <Select defaultValue={ countries[11].country } onChange={ onChange }>
            {
                countries.map((country) => {
                    return (
                        <Option key={ country.id } value={ country.country }>
                            { country.country }
                        </Option>
                    );
                })
            }
        </Select>
    )
}

export default CountrySelector;