import React from "react";

import { BodyOne, HeadingOne } from "../components/typography";
import AuthForm from "../components/forms/AuthForm";

const LoginSuccess = () => {
  return (
    <div className="flex justify-center mt-12">
      <div className="grid grid-rows-2 place-content-between gap-20">
        <div>
          <HeadingOne className="text-tripplo-200 text-center mt-4 mb-4">
            Login Successful
          </HeadingOne>
          <AuthForm height="235px" active={true}>
            <div className="flex flex-col p-2 mb-3">
              <div className="flex flex-col text-center">
                <BodyOne className="mb-2">You have signed in successfully, please wait...</BodyOne>
              </div>
            </div>
          </AuthForm>
        </div>
      </div>
    </div>
  );
}

export default LoginSuccess;