import React, { useState } from "react";
import PropTypes from "prop-types";

const subscript = (subscriptText) => {
  return (
    <span className="text-xs italic">
      {subscriptText}
    </span>
  )
}

const Input = ({ className, value, onChange, ref, inputSubscript = '', isPassword = false, ...props }) => {
  const [isHidden, setIsHidden] = useState(true);

  const showPasswordHandler = () => {
    setIsHidden(!isHidden);
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row flex-nowrap">
        <div className="flex flex-grow">
          <input
            style={{border: '1px solid red'}}
            className={`${className}
            flex-grow
            bg-tripplo-600 
            rounded-lg 
            py-3 
            px-3 
            border 
            border-tripplo-300 
            focus:outline-none 
            focus:bg-white`}
            type={
              isPassword
              ? (
                isHidden ? "password" : "text"
              ) : (
                "text"
              )
            }
            value={value}
            onChange={onChange}
            ref={ref}
            autoCapitalize="none"
            {...props}
          />
        </div>
        {
          isPassword
            ? (
              isHidden
                ? (
                  <div onClick={() => showPasswordHandler()} className="flex-initial self-center text-tripplo-200 font-medium border-2 border-tripplo-200 p-1 mx-1 rounded-md">
                    <span>Show</span>
                  </div>
                ) : (
                  <div onClick={() => showPasswordHandler()} className="flex-initial self-center text-white font-medium py-1 px-2 mx-1 rounded-md bg-tripplo-200">
                    <span>Hide</span>
                  </div>
                )
            ) : (
              <></>
            )
        }
      </div>
      {
        inputSubscript === '' ? '' : subscript(inputSubscript)
      }
    </div>
  );
};

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
Input.propTypes = propTypes;

export default Input;
