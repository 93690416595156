import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CelluarInput = ({ onChange }) => {
  const countries = [
    "za",
    "ao",
    "bw",
    "km",
    "cd",
    "ls",
    "mg",
    "mw",
    "mu",
    "mz",
    "na",
    "sc",
    "sd",
    "tz",
    "zm",
    "zw",
  ];

  return (
    <PhoneInput
      onChange={onChange}
      autoFormat={true}
      disableCountryCode={false}
      masks={{ za: ".. ... ...." }}
      defaultMask={"... ... ... ..."}
      country="za"
      onlyCountries={countries}
      def
    />
  );
};

export default CelluarInput;
